import React, { useEffect, useState } from "react";
import styles from "./privacyPolicyPage.module.css";
import hederImg from "../../img/hederIco.jpg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import backIco from "../../../src/icons/back.png";

const PP = () => {
  const lang = localStorage.getItem("language");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

 
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.full}>
        <div>
          <div className={styles.heder}>
            <img
              src={hederImg}
              alt="Next Flat"
              className={styles.animated_image}
              onClick={() => navigate("/")}
            />
          </div>
          <hr className={styles.bottomHR} />
        </div>
        <div>
          <button
            className={styles.backbtn}
            onClick={() => navigate("/")}
          >
            <img className={styles.backIco} alt="" src={backIco} />
            {t("Back")}
          </button>
        </div>
        <div className="privacy-policy text-xl font-poppins">
<h2 className="text-xl font-semibold mb-4">{t('privacy_policy.title')}</h2>

  <h3 className="text-lg font-semibold">{t('privacy_policy.introduction_title')}</h3>
  <p>{t('privacy_policy.introduction_text')}</p>

  <h3 className="text-lg font-semibold">{t('privacy_policy.collection_of_data_title')}</h3>
  <p>{t('privacy_policy.collection_of_data_text')}</p>

  <h3 className="text-lg font-semibold">{t('privacy_policy.data_usage_title')}</h3>
  <p>{t('privacy_policy.data_usage_text')}</p>

  <h3 className="text-lg font-semibold">{t('privacy_policy.data_storage_title')}</h3>
  <p>{t('privacy_policy.data_storage_text')}</p>

  <h3 className="text-lg font-semibold">{t('privacy_policy.data_security_title')}</h3>
  <p>{t('privacy_policy.data_security_text')}</p>

  <h3 className="text-lg font-semibold">{t('privacy_policy.user_rights_title')}</h3>
  <p>{t('privacy_policy.user_rights_text')}</p>

  <h3 className="text-lg font-semibold">{t('privacy_policy.contact_title')}</h3>
  <p>{t('privacy_policy.contact_text')}</p>
</div>

     

</div>


 
    </>
  );
};

export default PP;
