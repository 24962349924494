import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Heder from "../../components/heder/Heder";
import { useNavigate } from "react-router-dom";
import styles from './PageNotFound.module.css'

const Main = () => {
  const lang = localStorage.getItem("language");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();


  useEffect(() => {
    i18n.changeLanguage(lang);

  }, [i18n, lang]);



  return (
    <>
      <div className={styles.main}>
        <img src="https://speckyboy.com/wp-content/uploads/2022/02/404-page-web-design-inspiration-14.jpg"></img>
      </div>

    </>
  );
};

export default Main;
