import React, { useState, useEffect } from "react";
import styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import hederImg from "../../img/footerico.png";


const Footer = ({ scrollToTop }) => {
  const lang = localStorage.getItem("language");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);
 
  const goTOFaq =() => {
    navigate("/faq");

  }

  const goTOPP =() => {
    navigate("/privacy-policy");

  }
  
  return (
    <>
      <div className={styles.main}>
        <div className={styles.round}>
            <div className={styles.top}>
               <div className={styles.topLeft}>
               <img src={hederImg} className={styles.img}></img>
                <div className={styles.centerButtonGroup}>
                  <span>{t("About us")}</span>
                  <span>{t("Contact us")}</span>
                  <span onClick={goTOFaq}>FAQ</span>
                  <span onClick={goTOPP}>Privacy and policy</span>
                </div>
                
               </div>
               <span className={styles.upButton} onClick={scrollToTop}><i class="bi bi-chevron-double-up"></i></span>
            </div>
            <div className={styles.bottom}>
            <div className={styles.leftLogos}>
              <i class="bi bi-instagram"></i>
              <i class="bi bi-youtube"></i>
              <i class="bi bi-facebook"></i>
              <i class="bi bi-linkedin"></i>

              </div>

              <span className={styles.SpanOfRights}>&copy; 2025 Next Flat. All Rights Reserved.</span>
            </div>
          

        </div>
      </div>
    </>
  );
};

export default Footer;
