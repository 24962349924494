import React, { useEffect, useState } from "react";
import styles from "./faqPage.module.css";
import hederImg from "../../img/hederIco.jpg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import backIco from "../../../src/icons/back.png";

const Faq = () => {
  const lang = localStorage.getItem("language");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

 
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.full}>
        <div>
          <div className={styles.heder}>
            <img
              src={hederImg}
              alt="Next Flat"
              className={styles.animated_image}
              onClick={() => navigate("/")}
            />
          </div>
          <hr className={styles.bottomHR} />
        </div>
        <div>
          <button
            className={styles.backbtn}
            onClick={() => navigate("/")}
          >
            <img className={styles.backIco} alt="" src={backIco} />
            {t("Back")}
          </button>
        </div>
     
        <div className="faq-item mb-6">
  <h2 className="text-xl font-semibold">{t('general_information')}</h2>
  <p><strong>{t('what_is_this_site_for')}</strong><br />{t('this_platform_helps')}</p>
  <p><strong>{t('do_i_need_an_account')}</strong><br />{t('yes_create_account')}</p>
</div>

<div className="faq-item mb-6">
  <h2 className="text-xl font-semibold">{t('account_management')}</h2>
  <p><strong>{t('how_do_i_register')}</strong><br />{t('click_register_button')}</p>
  <p><strong>{t('forgot_password')}</strong><br />{t('go_to_login_page')}</p>
  <p><strong>{t('can_i_update_personal_information')}</strong><br />{t('go_to_profile_settings')}</p>
</div>

<div className="faq-item mb-6">
  <h2 className="text-xl font-semibold">{t('using_the_platform')}</h2>
  <p><strong>{t('how_do_i_add_an_announcement')}</strong><br />{t('step_1_log_in')}<br />{t('step_2_click_add_announcement')}<br />{t('step_3_fill_details_and_upload_images')}<br />{t('step_4_submit_for_approval')}</p>
  <p><strong>{t('how_can_i_edit_or_delete')}</strong><br />{t('manage_announcements_in_dashboard')}</p>
  <p><strong>{t('how_do_i_rate_a_seller')}</strong><br />{t('leave_a_rating_after_transaction')}</p>
</div>

<div className="faq-item mb-6">
  <h2 className="text-xl font-semibold">{t('safety_and_privacy')}</h2>
  <p><strong>{t('is_my_personal_data_safe')}</strong><br />{t('we_prioritize_privacy')}</p>
  <p><strong>{t('what_safety_measures')}</strong><br />{t('meet_in_public_safe_places')}</p>
</div>

<div className="faq-item mb-6">
  <h2 className="text-xl font-semibold">{t('payments')}</h2>
  <p><strong>{t('does_the_platform_handle_payments')}</strong><br />{t('platform_fosters_connections')}</p>
  <p><strong>{t('are_there_fees')}</strong><br />{t('basic_usage_free')}</p>
</div>

<div className="faq-item mb-6">
  <h2 className="text-xl font-semibold">{t('troubleshooting_and_support')}</h2>
  <p><strong>{t('facing_issues_with_account')}</strong><br />{t('contact_support_team')}</p>
  <p><strong>{t('cant_see_my_announcement')}</strong><br />{t('announcement_review_in_progress')}</p>
  <p><strong>{t('encounter_inappropriate_content')}</strong><br />{t('report_the_announcement')}</p>
</div>

<div className="faq-item mb-6">
  <h2 className="text-xl font-semibold">{t('additional_features')}</h2>
  <p><strong>{t('can_i_search_for_apartments')}</strong><br />{t('use_search_bar_to_filter')}</p>
  <p><strong>{t('do_you_offer_translation_support')}</strong><br />{t('platform_supports_multiple_languages')}</p>
  <p><strong>{t('whats_the_difference')}</strong><br />{t('renting_vs_leasing')}</p>
  <p><strong>{t('still_have_questions')}</strong><br />{t('contact_us_or_check_help_center')}</p>
</div>

</div>


 
    </>
  );
};

export default Faq;
